<template>
  <div>
    <div class="page-wrapper">
      <div class="error-wrapper">
        <div class="container"><img class="img-100" src="~/assets/images/other-images/sad.png" alt="">
          <div class="error-heading">
            <h2 class="headline font-primary">500</h2>
          </div>
          <div class="col-md-8 offset-md-2">
            <p class="sub-content">
              {{this.$t('messages.error.pageNotAvailable')}}
            </p>
          </div>
          <div>
            <router-link class="btn btn-primary-gradien btn-lg" :to="{ name: 'MainPanel' }">
              {{this.$t('messages.error.backToHome')}}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
